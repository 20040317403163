import { AlertContext } from "@components/contexts/AlertContext";
import { AppContext } from "@components/contexts/AppContext";
import EmailVerificationDialog from "@components/login/EmailVerificationDialog";
import LoginPage from "@components/login/LoginPage";
import colorlog from "@libs/colorlog";
import setCloudFlow from "@libs/flows/setCloudFlow";
import { GUID_MAP, PRODUCT_MAP } from "@libs/products";
import { PAGES } from "@libs/web/urls";
import withSanitisedQuery from "@libs/withSanitisedQuery";
import {
  AuthCredential,
  getAuth,
  linkWithCredential,
  onAuthStateChanged,
} from "firebase/auth";
import type { NextPage } from "next";
import { useRouter } from "next/router";
import { useContext, useEffect, useState } from "react";
import { useQueryClient } from "react-query";
interface Props {
  show: boolean;
}

const log = colorlog("pages");

const Login: NextPage<Props> = () => {
  const router = useRouter();
  const ctxAlert = useContext(AlertContext);
  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [loginLoading, setLoginLoading] = useState(false);
  const [pendingCredential, setPendingCredential] = useState<string>("");
  const [intitialLoginAttempt, setInitialLoginAttempt] =
    useState<boolean>(false);
  const queryClient = useQueryClient();

  const {
    redirectapp = "",
    product = "",
    redirectpath,
    redirect,
  } = router.query;
  if (!redirectapp && !redirect) {
    router.query.redirectapp = "cloud";
  }

  if (product && !redirect) {
    const guid: string = PRODUCT_MAP[product.toString()];
    router.query.redirectapp = "cloud";
    router.query.redirectpath = guid ? `/sift/${guid}` : "";
  } else {
    if (redirectpath && !redirect) {
      let productRs;
      Object.keys(GUID_MAP).forEach((e) => {
        if (redirectpath.includes(e)) {
          productRs = GUID_MAP[e];
        }
      });
      if (productRs) {
        router.query.product = productRs;
      }
    }
  }

  const ctx = useContext(AppContext);

  const setCredential = (c: string) => {
    setPendingCredential(c);
  };

  // Listen to the Firebase Auth state and set the local state.
  useEffect(() => {
    const auth = getAuth();
    async function attemptToLogin() {
      setLoginLoading(true);
      setInitialLoginAttempt(true);
      const res = await setCloudFlow({
        auth,
        ctx,
        router,
        setOpen,
      });
      if (res?.error) {
        setLoginLoading(false);
      }
    }
    if (!intitialLoginAttempt) {
      attemptToLogin();
    }

    log.debug("setting observer...");
    const unregisterAuthObserver = onAuthStateChanged(
      auth,
      async (userFirebase) => {
        // Clean user when try login again with another user on the query params to automate login
        if (userFirebase) {
          const query = router.query;
          const {
            redirectapp,
            _ga = "",
            product = "",
            utm_source = "",
            utm_content = "",
            utm_medium = "",
            utm_campaign = "",
            utm_keyword = "",
          } = query;
          const tracking = {
            _ga,
            product,
            utm_source,
            utm_content,
            utm_medium,
            utm_campaign,
            utm_keyword,
          };

          const token = await userFirebase.getIdToken();
          if (auth.currentUser?.email) {
            setEmail(auth.currentUser?.email);
          }
          if (pendingCredential && auth.currentUser) {
            const resp = await linkWithCredential(
              auth.currentUser,
              pendingCredential as unknown as AuthCredential
            );
            ctxAlert.setAlert("You have successfully linked your identity.");
          }
          const headers: HeadersInit = new Headers();
          headers.set("authorization", "GIDP " + token!);

          log.debug("onAuthStateChanged::setting cloud flow...");
          setLoginLoading(true);
          const resp = await setCloudFlow({
            token: token,
            auth: auth,
            router: router,
            setOpen: setOpen,
            ctx: ctx,
            tracking,
          });
          if (resp?.error) {
            setLoginLoading(false);
            try {
              // Clear jwt cookie
              await auth.signOut();
              await fetch("/api/logout");
              await router.replace({
                pathname: PAGES.login,
                query: {
                  ...router.query,
                },
              });
              ctxAlert.setAlert(resp?.message || "Error logging in", "error");
            } finally {
              // clear all the queries and info from cache
              queryClient.removeQueries();
              ctx.setLoading(false);
            }
          }
        } else {
          // User is signed out
          log.debug("onAuthStageChanged: signed out");
          // ...
        }
      }
    );

    return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
  }, [ctx, ctxAlert, email, router, queryClient, pendingCredential]);

  if (ctx.isLoading) {
    return null;
  }

  return (
    <>
      <LoginPage
        isSignUp={false}
        isLoading={loginLoading}
        setPendingCredential={setCredential}
      />
      <EmailVerificationDialog openProps={open} email={email} setLoginLoading={setLoginLoading} />
    </>
  );
};

export default withSanitisedQuery(Login);
